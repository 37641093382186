import { get, post } from './fetchService';

export const createCacheAdministrationService = (accessToken: string) => {
    return {
        getAllCacheValues: async (): Promise<CacheEntry[]> => {
            return await get(`/admin-tool/cache-administration/all`, accessToken);
        },
        saveCacheValue: async (entry: CacheEntry): Promise<void> => {
            return await post(`/admin-tool/cache-administration/${entry.key}`, { value: entry.value }, accessToken);
        }
    };
};

export interface CacheEntry {
    key: string;
    value: string;
}
