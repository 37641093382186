<template>
    <v-navigation-drawer app theme="dark" width="200">
        <v-list>
            <v-list-item link v-for="item in items" :prepend-icon="item.icon" :key="item.text" :to="item.link" :class="{ 'submenu-class': item.submenu }">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'Sidebar',
    setup() {
        const store = useStore();
        const isLoggedIn = computed(() => store.getters.isLoggedIn);

        const items = computed(() => {
            if (!store.getters.isLoggedIn) return [];

            return [
                { icon: 'mdi-account-multiple', text: 'Players', link: '/players' },
                { icon: 'mdi-gamepad-variant', text: 'Tournaments', link: '/tournaments' },
                { icon: 'mdi-chevron-right', text: 'Settings', link: '/tournaments/settings', submenu: true },
                { icon: 'mdi-forum', text: 'Submissions', link: '/requests' },
                { icon: 'mdi-tune', text: 'Versions', link: '/cache-admin' },
                isLoggedIn.value ? { icon: 'mdi-logout', text: 'Logout', link: '/logout' } : { icon: 'mdi-login', text: 'Login', link: '/' }
            ];
        });
        return {
            items,
            isLoggedIn
        };
    }
});
</script>

<style scoped>
.submenu-class div {
    padding-left: 20px; /* Example style */
    /* Add other styles as needed */
}
</style>
