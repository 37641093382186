<template>
    <v-btn color="primary" @click="handleSave" :disabled="disabled || !eventBus.isConfirmed">
        <slot>Save</slot>
    </v-btn>
</template>
>

<script lang="ts" setup>
import { eventBus } from '@/store/eventBus';
import { computed } from 'vue';

const props = defineProps({
    save: {
        type: Function,
        required: true
    },
    disabled: {
        type: Boolean,
        default: true
    }
});

const isFormValid = computed(() => !props.disabled && eventBus.isConfirmed);

const handleSave = () => {
    if (isFormValid.value) {
        props.save();
    }
};
</script>
