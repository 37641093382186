<template>
    <div class="container">
        <v-data-table item-value="tournamentId" :header-props="headerProps" :loading="loading" :headers="headers" :items="tournaments" :row-props="getRowClass">
            <template v-slot:top>
                <v-toolbar color="black">
                    <v-toolbar-title>Tournaments</v-toolbar-title>
                    <v-checkbox v-model="showClosed" class="show-closed" label="Show closed" @change="onShowClosedChange"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ props }">
                            <v-sheet>
                                <v-fab
                                    v-bind="props"
                                    title="Create new tournament"
                                    color="primary"
                                    class="me-4"
                                    icon="mdi-plus"
                                    location="top end"
                                    absolute
                                    offset
                                ></v-fab>
                            </v-sheet>
                        </template>
                        <v-card min-height="640px">
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="nameField"
                                                validate-on="input"
                                                :rules="[rules.required]"
                                                v-model="editedItem.name"
                                                label="Name"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <DatePicker :min-date="new Date()" label="Start" v-model="editedItem.start" color="primary" />
                                        </v-col>
                                        <v-col cols="6">
                                            <DatePicker :min-date="new Date(editedItem.start)" label="End" v-model="editedItem.end" color="primary" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select :items="typeOptions" :rules="[rules.required]" v-model="editedItem.type" label="Type"></v-select>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field :rules="[rules.required, rules.version]" v-model="editedItem.version" label="Version"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-select :items="levelConfigs" v-model="editedItem.levelConfigSo" label="Level config"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-alert
                                                v-if="rules.overlappingDates(editedItem.type, editedItem.start, editedItem.end)"
                                                title="Overlapping dates"
                                                :text="rules.overlappingDates(editedItem.type, editedItem.start, editedItem.end)"
                                                border="start"
                                                type="error"
                                                variant="outlined"
                                                prominent
                                            ></v-alert>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="flex-wrap">
                                <v-spacer></v-spacer>
                                <ConfirmationTextField />
                                <v-btn @click="close"> Cancel </v-btn>
                                <ConfirmationSaveButton :save="save" :disabled="!isFormValid || loading">Save</ConfirmationSaveButton>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEndTournament" max-width="555px">
                        <v-card>
                            <v-card-title class="text-h5">Are you sure you want to end this tournament?</v-card-title>
                            <v-card-actions class="flex-wrap">
                                <ConfirmationTextField />
                                <v-btn @click="closeEndTournament">Cancel</v-btn>
                                <ConfirmationSaveButton :save="endTournamentConfirm" :disabled="!isFormValid">OK</ConfirmationSaveButton>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <v-icon :color="getStatusColor(item.end)" :title="getStatusTitle(item.start, item.end)">
                    {{ getStatusIcon(item.start, item.end) }}
                </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="me-3" @click="editItem(item)"> mdi-pencil </v-icon>
                <v-icon v-if="getStatusTitle(item.start, item.end) !== 'Closed'" title="End tournament" class="me-3" size="small" @click="endTournament(item)">
                    mdi-lock
                </v-icon>
                <v-icon class="me-3" title="Copy to clipboard" @click="copyToClipboard(item)"> mdi-content-copy </v-icon>
                <a :href="getAwsLink(item)" target="_blank" rel="noopener noreferrer" color="blue">
                    <v-icon title="Open in AWS"> mdi-open-in-new </v-icon>
                </a>
            </template>
        </v-data-table>
    </div>
</template>

<script lang="ts" setup>
import { v4 as uuidv4 } from 'uuid';
import { ref, computed, watch } from 'vue';
import Cookies from 'js-cookie';
import { type Tournament, createTournamentService } from '@/store/tournamentService';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { eventBus } from '@/store/eventBus';
import { createSettingsService, type LevelConfiguration } from '@/store/settingsService';
import { breakingTournamentVersion } from '@/store/constants';
import lt from 'semver/functions/lt';
import ConfirmationTextField from '../ConfirmationTextField.vue';
import ConfirmationSaveButton from '../ConfirmationSaveButton.vue';
const dialog = ref(false);
const dialogEndTournament = ref(false);
const loading = ref(true);
const showClosed = ref(false);
const tournaments = ref([] as Tournament[]);
const nameField = ref<{ validate: () => void } | null>(null);
const headers = ref([
    { title: 'Status', key: 'status', sortable: false },
    { title: 'Name', key: 'name', sortable: false },
    { title: 'Start', key: 'start', sortable: false },
    { title: 'End', key: 'end', sortable: false },
    { title: 'Type', key: 'type', sortable: false },
    { title: 'Level Config', key: 'levelConfigSo', sortable: false },
    { title: 'Version', key: 'version', sortable: false },
    { title: 'Actions', key: 'actions', sortable: false }
]);
const typeOptions = ['End Of Content'];
const levelConfigs = ref([] as string[]);
const levelConfigurations = ref([] as LevelConfiguration[]);
const headerProps = ref({
    class: 'header-row'
});
const defaultTournament: Omit<Tournament, 'name'> & Partial<Pick<Tournament, 'name'>> = {
    tournamentId: uuidv4(),
    name: undefined,
    type: 'EndOfContent',
    version: breakingTournamentVersion,
    levelConfigSo: '',
    start: new Date().toISOString(),
    end: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString()
};
const isCreateMode = ref(true);
const editedItem = ref({ ...defaultTournament });

const resetToCreateMode = () => {
    console.log('Reset state to create mode');
    editedItem.value = {
        ...defaultTournament,
        tournamentId: uuidv4()
    };
    isCreateMode.value = true;
};
const formTitle = computed(() => {
    return isCreateMode.value ? 'Create New Tournament' : 'Edit Tournament';
});

const rules = {
    required: (value: string | undefined) => !!value || 'Field is required',
    version: (version: string) => {
        const versionRegex = /^\d+\.\d+\.\d+$/;
        const validVersion = versionRegex.test(version);
        if (!validVersion) {
            return 'Invalid version';
        }
        if (lt(version, breakingTournamentVersion)) {
            return 'Version must be >= ' + breakingTournamentVersion;
        }
        return true;
    },
    overlappingDates: (type: string, start: string, end: string) => {
        if (!start || !end) {
            return true; // If no dates are provided, validation is skipped.
        }

        // Convert strings to Date objects
        const newStartDate = new Date(start);
        const newEndDate = new Date(end);

        // Check for overlap with any existing tournament
        const overlapsWith = tournaments.value.find((tournament) => {
            if (tournament.type !== type) return false;
            if (tournament.tournamentId === editedItem.value.tournamentId) return false;

            const existingStart = new Date(tournament.start);
            const existingEnd = new Date(tournament.end);

            // Check if the new tournament starts or ends within an existing tournament
            const startsDuringExisting = newStartDate > existingStart && newStartDate < existingEnd;
            const endsDuringExisting = newEndDate > existingStart && newEndDate < existingEnd;
            // Check if the new tournament completely wraps an existing tournament
            const wrapsExisting = newStartDate <= existingStart && newEndDate >= existingEnd;

            return startsDuringExisting || endsDuringExisting || wrapsExisting;
        });

        return overlapsWith
            ? `Tournament "${overlapsWith.name}" begins ${new Date(overlapsWith.start).toLocaleString()} and ends ${new Date(overlapsWith.end).toLocaleString()}`
            : false;
    },
    date: (value: string) => {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        return datePattern.test(value) || 'Invalid date format. Use YYYY-MM-DD.';
    }
};

const isFormValid = computed(() => {
    const isNameValid = rules.required(editedItem.value.name) === true;
    const isTypeValid = rules.required(editedItem.value.type) === true;
    const isVersionValid = rules.version(editedItem.value.version) === true;
    const areDatesValid = rules.overlappingDates(editedItem.value.type, editedItem.value.start, editedItem.value.end) === false;

    return isNameValid && isTypeValid && isVersionValid && areDatesValid;
});

watch(
    () => editedItem.value.start,
    (newStartDate) => {
        if (new Date(newStartDate) > new Date(editedItem.value.end)) {
            editedItem.value.end = newStartDate;
        }
    }
);

watch(nameField, () => {
    if (!nameField.value) return;

    if (nameField.value) {
        nameField.value?.validate();
    }
});

const accessToken = Cookies.get('access_token')!;
const tournamentService = createTournamentService(accessToken);
const settingsService = createSettingsService(accessToken);

// Fetch data and other methods
async function fetchData() {
    try {
        tournaments.value = showClosed.value ? await tournamentService.getAllTournaments() : await tournamentService.getActiveTournaments();
        levelConfigurations.value = await settingsService.getLevelConfigurations();
        levelConfigs.value = levelConfigurations.value.map((config) => config.levelConfigSo).filter(Boolean); // Ensure no null/undefined values

        loading.value = false;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

function onShowClosedChange() {
    fetchData();
}

function getRowClass(item: { index: number }) {
    const className = item.index % 2 === 0 ? 'even-row' : 'odd-row';
    return { class: className };
}

async function save() {
    loading.value = true;
    if (!isCreateMode.value) {
        console.log('Update tournament', editedItem);
        await tournamentService.updateTournament(editedItem.value as Tournament);
    } else {
        console.log('Save new tournament', editedItem);
        try {
            await tournamentService.createTournament(editedItem.value as Tournament);
            editedItem.value.tournamentId = uuidv4();
        } catch (error) {
            console.error('Unexpected error occured while creating new tournament', error);
        }
    }

    // This is to avoid problems with consistent reads
    // Sometimes the fetch call is too quickly after the create or update
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await fetchData();

    close();
    loading.value = false;
}

function close() {
    dialog.value = false;
    resetToCreateMode();
}

function editItem(item: Tournament) {
    isCreateMode.value = false;
    editedItem.value = { ...item };
    console.log('Open dialog');
    dialog.value = true;
}

function endTournament(item: Tournament) {
    isCreateMode.value = false;
    editedItem.value = { ...item, end: new Date().toISOString() };
    if (new Date(item.start) > new Date()) {
        // If tournament starts in the future also make sure to change start date to now
        editedItem.value.start = editedItem.value.end;
    }
    dialogEndTournament.value = true;
}

async function copyToClipboard(item: Tournament) {
    const tournamentDetails = JSON.stringify(item, null, 2);

    try {
        await navigator.clipboard.writeText(tournamentDetails);
        console.log('Tournament details copied to clipboard:', tournamentDetails);
        eventBus.showSuccess('Successfully copied tournament to clipboard');
    } catch (err) {
        console.error('Failed to copy text to clipboard:', err);
    }
}

function getAwsLink(item: Tournament): string {
    return `https://eu-north-1.console.aws.amazon.com/dynamodbv2/home?region=eu-north-1#item-explorer?maximize=true&operation=QUERY&pk=tournament&sk=${item.tournamentId}&table=${process.env.VUE_APP_AWS_ENV}-tournaments`;
}

async function endTournamentConfirm() {
    try {
        await save();
        console.log('Ended tournament', editedItem.value.tournamentId);

        await fetchData();
    } catch (error: any) {
        eventBus.showError('Error ending tournament: ' + error.message);
        console.error('Error ending tournament:', error);
    } finally {
        closeEndTournament();
    }
}

function closeEndTournament() {
    dialogEndTournament.value = false;
    resetToCreateMode();
}

function getStatusIcon(startDate: string, endDate: string) {
    if (new Date(startDate) > new Date()) {
        return 'mdi-calendar-clock';
    }
    return new Date(endDate) > new Date() ? 'mdi-calendar-check-outline' : 'mdi-calendar-lock';
}

function getStatusColor(endDate: string) {
    return new Date(endDate) > new Date() ? 'green' : 'red';
}

function getStatusTitle(startDate: string, endDate: string) {
    if (new Date(startDate) > new Date()) {
        return 'Upcoming';
    }
    return new Date(endDate) > new Date() ? 'Active' : 'Closed';
}

fetchData();
</script>

<style>
.show-closed {
    margin-top: 26px;
}
.odd-row {
    background-color: #f2f2f2;
}

.even-row {
    background-color: white;
}

.header-row {
    background-color: black;
    color: white;
    font-weight: bold;
}
</style>

<style scoped>
.container {
    padding-top: 20px;
}
</style>
