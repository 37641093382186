<template>
    <MainLayout />
    <v-snackbar v-model="eventBus.snackbarVisible" :color="eventBus.snackbarColor">
        <div class="d-flex justify-space-between align-center" style="width: 100%">
            <span>{{ eventBus.snackbarMessage }}</span>
            <v-btn flat icon @click="eventBus.hideSnackbar" :color="eventBus.snackbarColor">
                <v-icon x-small>mdi-close</v-icon>
            </v-btn>
        </div>
    </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainLayout from '@/layouts/MainLayout.vue';
import { eventBus } from '@/store/eventBus';

export default defineComponent({
    name: 'App',
    components: {
        MainLayout
    },
    setup() {
        return { eventBus };
    }
});
</script>
