<template>
    <div>
        <v-dialog v-model="showInactivityDialog" max-width="500px">
            <v-card title="Session Timeout Warning">
                <template v-slot:prepend>
                    <v-icon color="warning" icon="mdi-alert"></v-icon>
                </template>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" id="1234robin">
                            <v-progress-linear v-model="percentDone"></v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" id="1234robin">
                            <p>Your session will expire in {{ countdown }} seconds due to inactivity.</p>
                            <p>Please click continue to remain logged in.</p>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="continueSession">Continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
import { ActionTypes } from '@/store/constants';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const showInactivityDialog = computed(() => store.state.inactivity.isInactivityWarning);
const countDownStart = 30;
const countdown = ref(countDownStart);
const percentDone = ref(0);
let countdownInterval: NodeJS.Timeout | null = null;

const startCountdown = () => {
    countdown.value = countDownStart;
    percentDone.value = 0;
    countdownInterval = setInterval(() => {
        if (countdown.value > 0) {
            countdown.value -= 1;
            percentDone.value = Math.round(((countDownStart - countdown.value) / countDownStart) * 100);
        } else {
            clearInterval(countdownInterval!);
            store.dispatch(ActionTypes.START_LOGOUT);
        }
    }, 1000);
};

const stopCountdown = () => {
    if (countdownInterval) {
        clearInterval(countdownInterval);
        countdownInterval = null;
    }
};

const continueSession = () => {
    store.dispatch(ActionTypes.START_INACTIVITY_MONITOR); // Restart the monitor to reset the timeout
    stopCountdown();
};

watch(showInactivityDialog, (newVal) => {
    if (newVal) {
        startCountdown();
    } else {
        stopCountdown();
    }
});
</script>
