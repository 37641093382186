/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default createVuetify({
    components,
    directives,
    // theme: {
    //   defaultTheme: 'dark',
    // },
    defaults: {
        global: {
            //color: 'primary' // Set the primary color as default globally
        }
        // Add other component defaults if needed
    }
});
