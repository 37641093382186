<template>
    <div class="container" />
</template>

<script setup lang="ts"></script>

<style scoped>
.container {
    position: relative;
    width: 100%;
    height: 365px;
    background-image: url('../assets/banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
</style>
