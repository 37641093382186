export const ActionTypes = {
    LOGIN: 'login',
    START_LOGOUT: 'startLogout',
    LOGOUT: 'logout',
    REFRESH_TOKEN: 'refreshToken',
    SETUP_COOKIE_REFRESH: 'setupCookieRefresh',
    START_INACTIVITY_MONITOR: 'startInactivityMonitor',
    STOP_INACTIVITY_MONITOR: 'stopInactivityMonitor'
} as const;

export const MutationTypes = {
    SET_EXPIRATION_TIME: 'setExpirationTime',
    SET_ERROR_MESSAGE: 'setErrorMessage',
    SET_LOGIN_STATE: 'setLoginState',
    SET_INACTIVITY_TIMEOUT_REFERENCE: 'setInactivityTimeoutReference'
} as const;

export const breakingTournamentVersion = '1.3.0';
