<template>
    <div class="container">
        <v-btn color="primary" v-if="isLoggedIn" @click="logout" class="bottom-right-button">Logout</v-btn>
        <v-btn color="primary" v-else @click="login" class="bottom-right-button">Login using Google</v-btn>
        <br />
        <label v-if="errorMessage" class="error">{{ errorMessage }}</label>
    </div>
</template>

<script setup lang="ts">
import { ActionTypes } from '@/store/constants';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const errorMessage = ref<string | null>(null);

const isLoggedIn = computed(() => store.getters.isLoggedIn);

const login = async () => {
    try {
        await store.dispatch(ActionTypes.LOGIN);
    } catch (error) {
        errorMessage.value = (error as Error).message;
    }
};

const logout = async () => {
    await store.dispatch(ActionTypes.LOGOUT);
};
</script>

<style scoped>
.error {
    color: red;
}

.container {
    position: relative;
    width: 100%;
}

.bottom-right-button {
    /* position: absolute; */
    /* bottom: 20px;
    right: 20px;
    padding: 10px 20px; */
    /* background-color: #007bff; */
    /* color: white; */
    /* border: none;
    border-radius: 5px;
    cursor: pointer; */
}

.bottom-right-button:hover {
    /* background-color: #0056b3; */
}
</style>
