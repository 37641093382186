import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cd4efaf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Login = _resolveComponent("Login")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "mx-auto my-8" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(" Admin-tool ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(" Rubik's Match administration tool ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (!_ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" Welcome, here you can administrate Tournaments and GDPR Submissions. ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, {
                      align: "center",
                      justify: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "4" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Please log in to access all the features of the admin tool. ")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Login)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" Use the menu to choose where to go next. ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}