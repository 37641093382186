import { get } from './fetchService';

export const createPlayerService = (accessToken: string) => {
    return {
        getPlayer: async (searchData: SearchPlayer): Promise<Player> => {
            const queryParams = new URLSearchParams();
            if (searchData.playerId) queryParams.append('playerId', searchData.playerId);
            if (searchData.email) queryParams.append('email', searchData.email);
            if (searchData.deviceIdHashed) queryParams.append('deviceIdHashed', searchData.deviceIdHashed);
            const url = `/admin-tool/players/?${queryParams.toString()}`;
            return await get(url, accessToken);
        }
    };
};

export interface Player {
    playerId: string;
    platformData?: PlatformData;
    deviceIdHashed: string;
    playerData: string;
}

export interface SearchPlayer {
    playerId: string;
    email: string;
    deviceIdHashed: string;
}

export interface PlatformData {
    playerId: string;
    googleId: string;
    googleEmail: string;
    facebookId: string;
}
