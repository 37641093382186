import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vue3GoogleLogin from 'vue3-google-login';
import './assets/main.css';
import { registerPlugins } from './plugins';
import store from './store';
import { ActionTypes } from './store/constants';

const app = createApp(App);
registerPlugins(app);

app.use(router);
app.use(store);
app.use(vue3GoogleLogin, {
    clientId: '949939290977-hidpu1kmfc2o8s1edsk95vk43sh56p8k.apps.googleusercontent.com'
});

app.mount('#app');

store.dispatch(ActionTypes.SETUP_COOKIE_REFRESH);
