import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex justify-space-between align-center",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLayout = _resolveComponent("MainLayout")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainLayout),
    _createVNode(_component_v_snackbar, {
      modelValue: _ctx.eventBus.snackbarVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.eventBus.snackbarVisible) = $event)),
      color: _ctx.eventBus.snackbarColor
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", null, _toDisplayString(_ctx.eventBus.snackbarMessage), 1),
          _createVNode(_component_v_btn, {
            flat: "",
            icon: "",
            onClick: _ctx.eventBus.hideSnackbar,
            color: _ctx.eventBus.snackbarColor
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { "x-small": "" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-close")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick", "color"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "color"])
  ], 64))
}