import store from '@/store';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';
import Home from '../components/Home.vue';
import Login from '../components/Login.vue';
import Logout from '../components/Logout.vue';
import Requests from '../components/Requests.vue';
import Tournaments from '../components/Tournaments/Index.vue';
import TournamentSettings from '../components/Tournaments/Settings.vue';
import CacheAdministration from '../components/CacheAdministration/Index.vue';
import Players from '../components/Players/Index.vue';

const defaultTitle = 'Admin tool';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/requests',
        name: 'Requests',
        component: Requests,
        meta: { requiresAuth: true }
    },
    {
        path: '/players',
        name: 'Players',
        component: Players,
        meta: { requiresAuth: true }
    },
    {
        path: '/tournaments',
        name: 'Tournaments',
        component: Tournaments,
        meta: { requiresAuth: true }
    },
    {
        path: '/tournaments/settings',
        name: 'Tournament settings',
        component: TournamentSettings,
        meta: { requiresAuth: true }
    },
    {
        path: '/cache-admin',
        name: 'Cache',
        component: CacheAdministration,
        meta: { requiresAuth: true }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/login',
        component: Login,
        name: 'Login'
    },
    {
        path: '/',
        name: 'Home',
        component: Home
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        const isAuthenticated = store.getters.isLoggedIn;
        if (isAuthenticated) {
            next();
        } else {
            next('/');
        }
    } else {
        next();
    }

    if (to.meta.title) {
        document.title = to.meta.title as string;
    } else {
        document.title = defaultTitle;
    }
});

export default router;
