import { baseUrlConfig } from './config';
import { eventBus } from '@/store/eventBus'; // Import the eventBus

interface FetchOptions extends RequestInit {
    headers?: Record<string, string>;
}

const fetchWrapper = async <T>(url: string, options: FetchOptions): Promise<T> => {
    const defaultHeaders: Record<string, string> = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };

    const headers = {
        ...defaultHeaders,
        ...options.headers
    };

    const mergedOptions: FetchOptions = {
        ...options,
        headers
    };

    try {
        const response = await fetch(`${baseUrlConfig.baseURL}${url}`, mergedOptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return (await response.json()) as T;
    } catch (error) {
        console.error('Fetch error:', error);
        eventBus.showError('An unexpected error occured while sending a request to the API');
        throw error;
    }
};
export const get = async <T>(endpoint: string, accessToken?: string, options: FetchOptions = {}): Promise<T> => {
    const allOptions = accessToken
        ? {
              headers: {
                  ...options?.headers,
                  Authorization: `Bearer ${accessToken}`
              }
          }
        : options;

    return fetchWrapper<T>(endpoint, {
        method: 'GET',
        ...allOptions
    });
};

export const post = async <T, D>(endpoint: string, data: D, accessToken?: string, options: FetchOptions = {}): Promise<T> => {
    const allOptions = accessToken
        ? {
              headers: {
                  ...options?.headers,
                  Authorization: `Bearer ${accessToken}`
              }
          }
        : options;

    return fetchWrapper<T>(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        ...allOptions
    });
};

export const put = async (endpoint: string, data: unknown, accessToken?: string, options: FetchOptions = {}): Promise<void> => {
    const allOptions = accessToken
        ? {
              headers: {
                  ...options?.headers,
                  Authorization: `Bearer ${accessToken}`
              }
          }
        : options;

    fetchWrapper(endpoint, {
        method: 'PUT',
        body: JSON.stringify(data),
        ...allOptions
    });
};

export const del = async (endpoint: string, accessToken?: string, options: FetchOptions = {}): Promise<void> => {
    const allOptions = accessToken
        ? {
              headers: {
                  ...options?.headers,
                  Authorization: `Bearer ${accessToken}`
              }
          }
        : options;

    await fetchWrapper(endpoint, {
        method: 'DELETE',
        ...allOptions
    });
};
