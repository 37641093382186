import { reactive } from 'vue';

interface AdminToolState {
    snackbarVisible: boolean;
    snackbarMessage: string;
    snackbarColor: string;
    showSnackbar: (message: string, color?: string) => void;
    showSuccess: (message: string) => void;
    showError: (message: string) => void;
    hideSnackbar: () => void;
    isConfirmed: boolean;
}

export const eventBus = reactive<AdminToolState>({
    snackbarVisible: false,
    snackbarMessage: '',
    snackbarColor: 'success',
    isConfirmed: false,

    showSuccess: (message: string) => {
        eventBus.snackbarMessage = message;
        eventBus.snackbarColor = 'success';
        eventBus.snackbarVisible = true;
    },

    showError: (message: string) => {
        eventBus.snackbarMessage = message;
        eventBus.snackbarColor = 'error';
        eventBus.snackbarVisible = true;
    },

    showSnackbar: (message: string, color: string = 'success') => {
        eventBus.snackbarMessage = message;
        eventBus.snackbarColor = color;
        eventBus.snackbarVisible = true;
    },

    hideSnackbar: () => {
        eventBus.snackbarVisible = false;
    }
});
