<template>
    <v-text-field
        v-if="isEnabled()"
        style="width: 100%"
        variant="outlined"
        clearable
        @click:clear="emitIsValid"
        v-model="confirmationText"
        :label="`To confirm enter '${matchText}'`"
        :rules="[rules.isValid]"
        @input="emitIsValid"
    ></v-text-field>
</template>

<script lang="ts" setup>
import { eventBus } from '@/store/eventBus';
import { isProd } from '@/utils';
import { ref } from 'vue';

const isEnabled = () => {
    return (props.visible === undefined && isProd()) || props.visible;
};
const emit = defineEmits(['is-valid']);

const props = defineProps({
    matchText: {
        type: String,
        default: 'I AM SURE'
    },
    visible: {
        type: Boolean,
        default: undefined
    }
});

const confirmationText = ref('');

const rules = {
    isValid: (value: string) => value === props.matchText || `Text must match "${props.matchText}"`
};

const emitIsValid = () => {
    const valid = confirmationText.value === props.matchText;
    emit('is-valid', valid);
    eventBus.isConfirmed = valid;
};

if (!isEnabled()) {
    emit('is-valid', true);
    eventBus.isConfirmed = true;
}

eventBus.isConfirmed = isEnabled() ? false : true;
</script>
