<template>
    <div>Logging out...</div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ActionTypes } from '@/store/constants';

const store = useStore();
const router = useRouter();

onMounted(async () => {
    await store.dispatch(ActionTypes.LOGOUT);
    router.push('/');
});
</script>

<style scoped>
/* Add any styles if needed */
</style>
