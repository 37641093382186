<template>
    <v-card>
        <v-tabs v-model="tab" bg-color="black">
            <v-tab value="one">Standard Fake Player Settings</v-tab>
            <v-tab value="two">Fake Player Schedules</v-tab>
            <v-tab value="three">Level configurations</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item value="one">
                <v-card outlined subtitle="Min and max points that will be aggregated during a day" title="Standard Fake Player Settings">
                    <v-card-text>
                        <v-text-field v-model="data.min" :readonly="loading" :rules="[required, rangeCheck]" label="Min points" />
                        <v-text-field v-model="data.max" :readonly="loading" :rules="[required, rangeCheck]" label="Max points" />
                    </v-card-text>
                    <template v-slot:actions>
                        <v-btn color="primary" @click="updatePointRange" :loading="loading">
                            <template v-slot:loader>
                                <v-progress-circular v-if="loading" color="primary" indeterminate />
                            </template>
                            Update
                        </v-btn>
                    </template>
                </v-card>
            </v-window-item>

            <v-window-item value="two">
                <v-card outlined title="Fake Player Schedules">
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <h3>Standard Fake Player</h3>
                            </v-col>
                            <v-col cols="6">
                                <h3>Dynamic Fake Player</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="2">
                                <v-text-field v-model="schedules.standard.value" :readonly="loading" :rules="[required, isInt]" label="Rate" />
                            </v-col>
                            <v-col cols="3">
                                <v-select v-model="schedules.standard.unit" :items="rateUnits" :readonly="loading" label="Unit" />
                            </v-col>
                            <v-col cols="1"></v-col>
                            <v-col cols="2">
                                <v-text-field v-model="schedules.dynamic.value" :readonly="loading" :rules="[required, isInt]" label="Rate" />
                            </v-col>
                            <v-col cols="3">
                                <v-select v-model="schedules.dynamic.unit" :items="rateUnits" :readonly="loading" label="Unit" />
                            </v-col>
                            <v-col cols="1"></v-col>
                        </v-row>
                    </v-card-text>
                    <template v-slot:actions>
                        <v-btn color="primary" @click="updateSchedule" :loading="loading">
                            <template v-slot:loader>
                                <v-progress-circular v-if="loading" color="primary" indeterminate />
                            </template>
                            Update
                        </v-btn>
                    </template>
                </v-card>
            </v-window-item>

            <v-window-item value="three">
                <v-card outlined title="Level Configurations">
                    <v-card-text>
                        <v-data-table item-value="id" :header-props="headerProps" :loading="loading" :headers="headers" :items="levelConfigs">
                            <template v-slot:top>
                                <v-toolbar color="black">
                                    <v-toolbar-title>Level Configurations</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-spacer></v-spacer>
                                    <v-spacer></v-spacer>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialog" max-width="500px">
                                        <template v-slot:activator="{ props }">
                                            <v-sheet>
                                                <v-fab
                                                    v-bind="props"
                                                    title="Create new Level Configuration"
                                                    color="primary"
                                                    class="me-4"
                                                    icon="mdi-plus"
                                                    location="top end"
                                                    absolute
                                                    offset
                                                ></v-fab>
                                            </v-sheet>
                                        </template>
                                        <v-form ref="form" v-model="formIsValid">
                                            <v-card height="700px">
                                                <v-card-title>
                                                    <span class="text-h5">{{ formTitle }}</span>
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12">
                                                                <v-text-field
                                                                    :rules="[rules.required, rules.uniqueConfig]"
                                                                    v-model="editedItem.levelConfigSo"
                                                                    label="Level config SO"
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <v-select
                                                                    :items="typeOptions"
                                                                    :rules="[rules.required]"
                                                                    v-model="editedItem.type"
                                                                    label="Type"
                                                                ></v-select>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn @click="close"> Cancel </v-btn>
                                                    <v-btn :disabled="!formIsValid" color="primary" @click="saveLevelConfig"> Save </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-form>
                                    </v-dialog>
                                    <v-dialog v-model="dialogDeleteLevelConfiguration" max-width="555px">
                                        <v-card>
                                            <v-card-title class="text-h5">Do you want to remove this level configuration?</v-card-title>
                                            <v-card-actions>
                                                <v-btn @click="closeDialogDeleteLevelConfiguration">Cancel</v-btn>
                                                <v-btn @click="deleteLevelConfigurationConfirm">OK</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon title="Delete " class="me-3" size="small" @click="deleteLevelConfiguration(item)"> mdi-trash-can </v-icon>
                            </template>
                        </v-data-table></v-card-text
                    >
                </v-card>
            </v-window-item>
        </v-window>
    </v-card>
</template>

<script lang="ts" setup>
import { ref, computed, type Ref } from 'vue';
import Cookies from 'js-cookie';
import { createSettingsService, type PointRange, type Schedules, type LevelConfiguration } from '@/store/settingsService';
import { eventBus } from '@/store/eventBus';
import { v4 as uuidv4 } from 'uuid';
const dialog = ref(false);
const dialogDeleteLevelConfiguration = ref(false);
const tab = ref('one');
const loading = ref(true);
const formIsValid = ref(false);
const form: Ref<HTMLFormElement | null> = ref(null);
const rateUnits = ['minutes', 'hours'];
// Initialize the data object with default values
const data = ref<PointRange>({ min: 20, max: 40 });
const schedules = ref<Schedules>({ dynamic: { value: 1, unit: 'hours' }, standard: { value: 1, unit: 'hours' } });
const levelConfigs = ref([] as LevelConfiguration[]);
const accessToken = Cookies.get('access_token')!;
const settingsService = createSettingsService(accessToken);
const isCreateMode = ref(true);
const headers = ref([
    { title: 'Type', key: 'type', sortable: false },
    { title: 'Config name', key: 'levelConfigSo', sortable: false },
    { title: 'Actions', key: 'actions', sortable: false }
]);
const headerProps = ref({
    class: 'header-row'
});
const formTitle = computed(() => {
    return isCreateMode.value ? 'Add New Level Configuration' : 'Edit Level Configuration';
});

const defaultSetting: { levelConfigSo: string; id: string; type: string } = {
    id: uuidv4().toString(),
    type: 'EndOfContent',
    levelConfigSo: ''
};

const editedItem = ref(defaultSetting);
async function fetchData() {
    try {
        data.value = await settingsService.settings.getPointRange();
        schedules.value = await settingsService.settings.getSchedule();
        levelConfigs.value = await settingsService.getLevelConfigurations();

        loading.value = false;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

async function updatePointRange() {
    console.log('Update point range', data.value);
    try {
        loading.value = true;
        const newRange: PointRange = {
            max: parseInt(data.value.max.toString()),
            min: parseInt(data.value.min.toString())
        };
        data.value = await settingsService.settings.setPointRange(newRange);
        // This is to avoid problems with propagation of changes to all lambdas
        await new Promise((resolve) => setTimeout(resolve, 1000));
        loading.value = false;
        eventBus.showSuccess('Successfully updated point range');
    } catch (error) {
        console.error('Error updating point range:', error);
    }

    // Add the logic to update the point range here
    await fetchData();
}

async function updateSchedule() {
    console.log('Update schedule', schedules.value);
    try {
        loading.value = true;
        const newSchedule: Schedules = {
            dynamic: { ...schedules.value.dynamic, value: parseInt(schedules.value.dynamic.value.toString()) },
            standard: { ...schedules.value.standard, value: parseInt(schedules.value.standard.value.toString()) }
        };
        await settingsService.settings.setSchedule(newSchedule);
        loading.value = false;
        eventBus.showSuccess('Successfully updated schedule');
    } catch (error) {
        console.error('Error updating schedule:', error);
    }

    // Add the logic to update the point range here
    await fetchData();
}

function required(value: string) {
    return !!value || 'Field is required';
}

function rangeCheck() {
    if (parseInt(data.value.max.toString()) < parseInt(data.value.min.toString())) return 'Max needs to be >= Min';

    return true;
}

function isInt(value: string) {
    const parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
        return 'Needs to be a number';
    } else {
        return true;
    }
}
async function saveLevelConfig() {
    const isValid = await form?.value?.validate();
    if (!isValid) {
        console.error('Form is invalid');
        return;
    }
    try {
        editedItem.value.id = uuidv4();
        await settingsService.setLevelConfigurations(editedItem.value as LevelConfiguration);
    } catch (error) {
        console.error('Unexpected error occured while creating new level configuration', error);
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    await fetchData();

    close();
}

async function deleteLevelConfigurationConfirm() {
    try {
        await settingsService.deleteLevelConfigurations(editedItem.value);
        await fetchData();
    } catch (error: any) {
        eventBus.showError('Error ending tournament: ' + error.message);
        console.error('Error ending tournament:', error);
    } finally {
        closeDialogDeleteLevelConfiguration();
    }
}
function deleteLevelConfiguration(item: LevelConfiguration) {
    isCreateMode.value = false;
    editedItem.value = { ...item };
    dialogDeleteLevelConfiguration.value = true;
}
function closeDialogDeleteLevelConfiguration() {
    dialogDeleteLevelConfiguration.value = false;
    resetToCreateMode();
}
const resetToCreateMode = () => {
    console.log('Reset state to create mode');
    editedItem.value = {
        ...defaultSetting
    };
    isCreateMode.value = true;
};
function close() {
    dialog.value = false;
    resetToCreateMode();
}

fetchData();
// Fetch data and other methods

const rules = {
    required: (value: string) => !!value || 'Field is required',
    uniqueConfig: (value: string) => {
        const exists = levelConfigs.value.some((config) => config.levelConfigSo === value);
        return !exists || 'LevelConfigSo name must be unique';
    }
};
const typeOptions = ['End Of Content'];
</script>

<style scoped>
.container {
    padding-top: 20px;
}
</style>
