<template>
    <div>
        <v-data-table
            item-value="pk"
            v-model:expanded="expanded"
            :header-props="headerProps"
            :loading="loading"
            :headers="headers"
            :items="data"
            :row-props="getRowClass"
            show-expand
        >
            <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                        <v-container>
                            <v-card variant="outlined" :subtitle="`Subject Request Id: ${item.pk}`" :title="capitalizeTitle(`${item.type} request`)">
                                <v-list>
                                    <v-list-item>
                                        <p>This {{ item.type }} submission was made for propertyId {{ item.propertyId }} on {{ item.platform }}</p>
                                    </v-list-item>
                                    <v-list-item>
                                        <p>
                                            It was received by AppsFlyer at
                                            {{ item.receivedTime }} and is expected to be completed at
                                            {{ item.expectedCompletionTime }}
                                        </p>
                                    </v-list-item>
                                </v-list>
                                <v-list>
                                    <v-list-item>
                                        <p>With the following Subject Identities</p>
                                    </v-list-item>
                                    <v-list-item v-for="(identity, index) in item.subjectIdentities" :key="index">
                                        <p>{{ identity.identityType }} {{ identity.identityValue }}</p>
                                    </v-list-item>
                                </v-list>
                                <template v-slot:actions>
                                    <v-btn color="info" text="Check status"></v-btn>
                                    <v-btn color="info" text="Resubmit"></v-btn>
                                </template>
                            </v-card>
                        </v-container>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Cookies from 'js-cookie';
import { get, post } from '@/store/fetchService';

interface RequestItem {
    pk: string;
    sk: string;
    playerId: string;
    email: string;
    platform: string;
    propertyId: string;
    subjectIdentities: string[];
    expectedCompletionTime: string;
    receivedTime: string;
    requestStatus: string;
    requestId: string;
}

export default defineComponent({
    name: 'Requests',
    data() {
        return {
            message: 'Request',
            data: [] as RequestItem[],
            headers: [
                {
                    title: 'RequestId',
                    key: 'pk',
                    align: 'start',
                    sortable: false
                },
                { title: 'Type', key: 'type' },
                { title: 'PlayerId', key: 'playerId' },
                { title: 'Email', key: 'email' },
                { title: 'Expected Completion Time', key: 'expectedCompletionTime' },
                { title: 'Status', key: 'requestStatus' }
            ],
            headerProps: {
                sortIcon: 'mdi-menu-up',
                class: 'header-row'
            },
            loading: true,
            expanded: []
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const accessToken = Cookies.get('access_token')!;
                const response = await get<{ gdprRequests: RequestItem[] }>('/admin-tool/requests', accessToken);
                if (response && Array.isArray(response.gdprRequests)) {
                    const rawData: RequestItem[] = response.gdprRequests;

                    const mergedData: { [key: string]: RequestItem } = {};
                    rawData.forEach((item: RequestItem) => {
                        if (!mergedData[item.pk]) {
                            mergedData[item.pk] = { ...item };
                        } else {
                            Object.assign(mergedData[item.pk], item);
                        }
                    });

                    this.data = Object.values(mergedData).map((item) => ({
                        ...item,
                        expectedCompletionTime: new Date(item.expectedCompletionTime).toLocaleString(),
                        receivedTime: new Date(item.receivedTime).toLocaleString()
                    }));
                    this.loading = false;
                } else {
                    console.error('Invalid response structure:', response);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        getRowClass(item: { index: number }) {
            const className = item.index % 2 === 0 ? 'even-row' : 'odd-row';
            return { class: className };
        },
        async resendRequest(id: string) {
            try {
                const accessToken = Cookies.get('access_token')!;
                await post('/admin-tool/requests', { id }, accessToken);
                await this.fetchData();
            } catch (error) {
                console.error('Error sending data to Lambda:', error);
            }
        },
        capitalizeTitle(title: string) {
            return title.replace(/\b\w/g, (char) => char.toUpperCase());
        }
    }
});
</script>

<style>
.odd-row {
    background-color: #f2f2f2;
}

.even-row {
    background-color: white;
}

.header-row {
    background-color: black;
    color: white;
    font-weight: bold;
}
</style>

<style scoped></style>
