import { clientId } from '@/store/config';
import { type ActionContext, type Commit, createStore, type Dispatch, type StoreOptions } from 'vuex';
import type { RootState } from './RootState';
import { ActionTypes, MutationTypes } from './constants';
import Cookies from 'js-cookie';
import { googleAuthCodeLogin, googleLogout } from 'vue3-google-login';
import { createAuthService } from './authService';
import { type AuthenticationData } from '@/types/AuthenticationData';
import { inactivityModule } from './inactivityStore';
import { type ValidateData } from '@/types/ValidateData';

const COOKIE_DURATION_MS = 60 * 60 * 1000; // 1 hour
const REFRESH_OFFSET_MS = 10 * 60 * 1000; // 10 minutes before expiration

const storeOptions: StoreOptions<RootState> = {
    state: {
        isLoggedIn: !!Cookies.get('access_token'),
        expirationTime: localStorage.getItem('cookieExpiration') ? new Date(parseInt(localStorage.getItem('cookieExpiration') || '0', 10)) : null,
        errorMessage: null,
        timeoutReference: null,
        inactivityTimeoutReference: null,
        inactivityTimeoutDuration: 15 * 60 * 1000,
        isInactivityWarning: false
    },
    mutations: {
        setLoginState(state, status: boolean) {
            state.isLoggedIn = status;
        },
        setExpirationTime(state: RootState, expirationTime: Date) {
            if (!expirationTime) {
                console.log('expirationTime not set yet', expirationTime);
                return;
            }
            state.expirationTime = expirationTime;
            localStorage.setItem('cookieExpiration', expirationTime.getTime().toString());
        },
        setErrorMessage(state: RootState, message: string) {
            state.errorMessage = message;
        }
    },
    actions: {
        startLogout() {
            window.location.href = '/logout';
        },
        async login({ commit, dispatch }) {
            try {
                const response = await googleAuthCodeLogin();
                const validateData: ValidateData = {
                    clientId: clientId.clientId,
                    token: response.code
                };

                const authenticationData = await createAuthService().validate(validateData);

                if (authenticationData) {
                    handleAuthenticationSuccess(authenticationData, commit, dispatch);
                    dispatch(ActionTypes.START_INACTIVITY_MONITOR);
                } else {
                    throw new Error(authenticationData || 'Validation failed');
                }
            } catch (error) {
                console.error('Login error:', error);
                throw error;
            }
        },
        async logout({ commit, dispatch }) {
            try {
                googleLogout();
                Cookies.remove('auth');
                Cookies.remove('access_token');
                Cookies.remove('refresh_token');
                commit(MutationTypes.SET_EXPIRATION_TIME, null);
                commit(MutationTypes.SET_LOGIN_STATE, false);
                localStorage.removeItem('cookieExpiration');
                dispatch(ActionTypes.STOP_INACTIVITY_MONITOR);
            } catch (error) {
                console.error('Error during logout:', error);
            }
        },
        setupCookieRefresh({ state, dispatch }: ActionContext<RootState, any>) {
            const currentTime = Date.now();
            if (state.expirationTime) {
                const timeUntilExpiration = state.expirationTime.getTime() - currentTime;

                if (timeUntilExpiration > REFRESH_OFFSET_MS && !state.timeoutReference) {
                    state.timeoutReference = setTimeout(() => dispatch(ActionTypes.REFRESH_TOKEN), timeUntilExpiration - REFRESH_OFFSET_MS);
                }
            } else {
                console.log('inside setupCookieRefresh but state.expirationTime is not set', state);
            }
        },
        async refreshToken({ state, commit, dispatch }: ActionContext<RootState, any>) {
            try {
                console.log('Start refreshing auth token');
                const refreshToken = Cookies.get('refresh_token')!;
                const authenticationData = await createAuthService().refreshToken(refreshToken);

                state.timeoutReference = null;
                handleAuthenticationSuccess(authenticationData, commit, dispatch);
                console.log('Auth token refreshed successfully');
            } catch (error: any) {
                commit(MutationTypes.SET_ERROR_MESSAGE, 'Failed to refresh the token');
            }
        }
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn
    },
    modules: {
        inactivity: inactivityModule
    }
};

const store = createStore<RootState>(storeOptions);

store.dispatch(ActionTypes.SETUP_COOKIE_REFRESH);
store.dispatch(ActionTypes.START_INACTIVITY_MONITOR);

export default store;

function getExpirationDate() {
    return new Date(Date.now() + COOKIE_DURATION_MS);
}

function handleAuthenticationSuccess(authenticationData: AuthenticationData, commit: Commit, dispatch: Dispatch) {
    const expirationDate = getExpirationDate();
    Cookies.set('auth', 'true', { expires: expirationDate });
    Cookies.set('access_token', authenticationData.accessToken, {
        expires: expirationDate
    });
    Cookies.set('refresh_token', authenticationData.refreshToken, {
        expires: expirationDate
    });
    commit(MutationTypes.SET_EXPIRATION_TIME, expirationDate);
    dispatch(ActionTypes.SETUP_COOKIE_REFRESH);
    commit(MutationTypes.SET_LOGIN_STATE, true);
}
