import { type StoreOptions } from 'vuex';
import { ActionTypes, MutationTypes } from './constants';
import { type RootState } from './RootState';

const DEFAULT_INACTIVITY_TIMEOUT_MS = 15 * 60 * 1000; // 15 minutes

type InactivityState = Pick<RootState, 'inactivityTimeoutDuration' | 'inactivityTimeoutReference' | 'isInactivityWarning'>;

export const inactivityModule: StoreOptions<InactivityState> = {
    state: {
        inactivityTimeoutReference: null,
        inactivityTimeoutDuration: DEFAULT_INACTIVITY_TIMEOUT_MS,
        isInactivityWarning: false
    },
    mutations: {
        setInactivityTimeoutDuration(state: InactivityState, duration: number) {
            state.inactivityTimeoutDuration = duration;
        },
        setInactivityTimeoutReference(state: InactivityState, timeoutReference: NodeJS.Timeout | null) {
            state.inactivityTimeoutReference = timeoutReference;
        }
    },
    actions: {
        startInactivityMonitor({ commit, state, dispatch }) {
            state.isInactivityWarning = false;
            const resetInactivityTimeout = () => {
                if (state.inactivityTimeoutReference) {
                    clearTimeout(state.inactivityTimeoutReference);
                }
                const timeout = setTimeout(() => {
                    state.isInactivityWarning = true;
                    dispatch(ActionTypes.STOP_INACTIVITY_MONITOR);
                }, state.inactivityTimeoutDuration);
                commit(MutationTypes.SET_INACTIVITY_TIMEOUT_REFERENCE, timeout);
            };

            // Reset inactivity timeout on user activity
            const activityEvents = ['mousemove', 'keydown', 'scroll', 'touchstart'];
            activityEvents.forEach((event) => {
                window.addEventListener(event, resetInactivityTimeout);
            });

            // Initialize the inactivity timeout
            resetInactivityTimeout();
        },
        stopInactivityMonitor({ commit, state }) {
            if (state.inactivityTimeoutReference) {
                clearTimeout(state.inactivityTimeoutReference);
                commit(MutationTypes.SET_INACTIVITY_TIMEOUT_REFERENCE, null);
            }
            // Remove event listeners
            const activityEvents = ['mousemove', 'keydown', 'scroll', 'touchstart'];
            activityEvents.forEach((event) => {
                window.removeEventListener(event, () => {});
            });
        }
    }
};
