<template>
    <div class="container">
        <v-card title="Search" outlined>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="item.playerId" label="Player Id"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="item.deviceIdHashed" label="Device Id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="item.email" label="Email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="onSearchButtonClicked()">Search</v-btn>
                        </v-col>
                        <v-col>
                            <v-alert v-if="errorMessage"> {{ errorMessage }} </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <v-card title="Player" outlined v-if="playerData">
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="playerData.playerId" label="Player Id"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="playerData.deviceIdHashed" label="Device Id"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-if="email" v-model="email" label="Email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card title="PlayerData json" variant="outlined">
                                <v-card-text>
                                    <vue-json-pretty :data="jsonData" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Cookies from 'js-cookie';
import { type Player, createPlayerService } from '@/store/playerService';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { eventBus } from '@/store/eventBus';

const loading = ref(true);

const playerData = ref<Player | null>(null);
const jsonData = ref('' as string);
const errorMessage = ref('');
const email = ref('');

const defaultPlayerSearch = {
    playerId: '',
    deviceIdHashed: '',
    email: ''
};
const item = ref({ ...defaultPlayerSearch });

const accessToken = Cookies.get('access_token')!;
const playerService = createPlayerService(accessToken);

// Fetch data and other methods
async function fetchData() {
    try {
        errorMessage.value = '';
        email.value = '';
        const response = await playerService.getPlayer(item.value);
        if (response && response.playerData) {
            playerData.value = response;
            jsonData.value = response.playerData;
            if (response.platformData) {
                email.value = response.platformData.googleEmail;
            }
        } else {
            // Set error message if no data is returned
            if (item.value.email) {
                errorMessage.value = 'No data found for player with email: ' + item.value.email;
            } else if (item.value.playerId) {
                errorMessage.value = 'No data found for player with player Id: ' + item.value.playerId;
            } else {
                errorMessage.value = 'No data found for player with device Id: ' + item.value.deviceIdHashed;
            }
            playerData.value = null;
        }

        loading.value = false;
    } catch (error: any) {
        eventBus.showError('Error fetching player data: ' + error.message);
        console.error('Error fetching player data:', error);
        playerData.value = null;
    }
    clearSearch();
}
const clearSearch = () => {
    item.value = { ...defaultPlayerSearch };
};

function onSearchButtonClicked() {
    fetchData();
}
</script>

<style></style>

<style scoped>
.container {
    padding-top: 20px;
}
</style>
