<template>
    <v-card class="mx-auto my-8">
        <v-card-item>
            <v-card-title> Admin-tool </v-card-title>

            <v-card-subtitle> Rubik's Match administration tool </v-card-subtitle>
        </v-card-item>

        <v-card-text>
            <div v-if="!isLoggedIn">
                <v-container>
                    <v-row>
                        <v-col> Welcome, here you can administrate Tournaments and GDPR Submissions. </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                        <v-col cols="4"> Please log in to access all the features of the admin tool. </v-col>
                        <v-col>
                            <Login />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div v-else>
                <v-container>
                    <v-row>
                        <v-col> Use the menu to choose where to go next. </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import Login from './Login.vue';

export default defineComponent({
    name: 'Home',
    components: {
        Login
    },
    setup() {
        const store = useStore();
        const isLoggedIn = computed(() => store.getters.isLoggedIn);

        return {
            isLoggedIn
        };
    }
});
</script>

<style scoped>
.welcome {
    padding-bottom: 20px;
}
</style>
