import { get, post, del } from './fetchService';
import { type Rate } from '@/store/tournamentService';

export const createSettingsService = (accessToken: string) => {
    return {
        settings: {
            getPointRange: async (): Promise<PointRange> => {
                return await get(`/admin-tool/tournaments/settings/point-range`, accessToken);
            },
            setPointRange: async (pointRange: PointRange): Promise<PointRange> => {
                return await post(`/admin-tool/tournaments/settings/point-range`, pointRange, accessToken);
            },
            getSchedule: async (): Promise<Schedules> => {
                return await get(`/admin-tool/tournaments/settings/schedule`, accessToken);
            },
            setSchedule: async (schedules: Schedules): Promise<Schedules> => {
                return await post(`/admin-tool/tournaments/settings/schedule`, schedules, accessToken);
            }
        },
        getLevelConfigurations: async (): Promise<LevelConfiguration[]> => {
            return await get(`/admin-tool/tournaments/settings/levelConfiguration`, accessToken);
        },
        setLevelConfigurations: async (levelConfiguration: LevelConfiguration): Promise<LevelConfiguration> => {
            return await post(`/admin-tool/tournaments/settings/levelConfiguration`, levelConfiguration, accessToken);
        },
        deleteLevelConfigurations: async (levelConfiguration: LevelConfiguration): Promise<void> => {
            return await del(`/admin-tool/tournaments/settings/levelConfiguration/${levelConfiguration.type}/${levelConfiguration.id}`, accessToken);
        }
    };
};

export interface LevelConfiguration {
    id: string;
    type: string;
    levelConfigSo: string;
}
export interface PointRange {
    max: number | string;
    min: number | string;
}
export interface Schedules {
    dynamic: Rate;
    standard: Rate;
}
