import { get, post, put } from './fetchService';

export const createTournamentService = (accessToken: string) => {
    return {
        getActiveTournaments: async (): Promise<Tournament[]> => {
            const response = await get<Tournament[]>('/tournament/active', accessToken);
            const tournaments = <Tournament[]>response.map((x: any) => ({
                ...x,
                end: new Date(x.end * 1000).toLocaleString(),
                start: new Date(x.start * 1000).toLocaleString()
            }));

            return tournaments;
        },
        getAllTournaments: async (): Promise<Tournament[]> => {
            const response = await get<Tournament[]>('/admin-tool/tournament', accessToken);
            const tournaments = <Tournament[]>response.map((x: any) => ({
                ...x,
                end: new Date(x.end * 1000).toLocaleString(),
                start: new Date(x.start * 1000).toLocaleString()
            }));

            return tournaments;
        },
        createTournament: async (tournament: Tournament): Promise<void> => {
            tournament.start = Math.floor(new Date(tournament.start).getTime() / 1000).toString();
            tournament.end = Math.floor(new Date(tournament.end).getTime() / 1000).toString();
            await post('/admin-tool/tournament', tournament, accessToken);
        },
        updateTournament: async (tournament: Tournament): Promise<void> => {
            tournament.start = Math.floor(new Date(tournament.start).getTime() / 1000).toString();
            tournament.end = Math.floor(new Date(tournament.end).getTime() / 1000).toString();
            await put('/admin-tool/tournament', tournament, accessToken);
        }
    };
};

export interface Tournament {
    tournamentId: string;
    name: string;
    start: string;
    end: string;
    type: string;
    levelConfigSo?: string;
    version: string;
}

export interface Rate {
    value: number;
    unit: 'minutes' | 'hours';
}
