interface BaseUrlConfig {
    baseURL: string;
}

interface ClientIdConfig {
    clientId: string;
}

const baseUrlConfig: BaseUrlConfig = {
    baseURL: process.env.VUE_APP_BASE_URL || ''
};

const clientId: ClientIdConfig = {
    clientId: process.env.VUE_APP_CLIENT_ID || ''
};

export { baseUrlConfig, clientId };
