<template>
    <v-card>
        <v-tabs v-model="tab" bg-color="black">
            <v-tab value="one">Cache administration</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item value="one">
                <v-card outlined subtitle="Update and persist cached values" title="Cache administration">
                    <v-list>
                        <v-list-item v-for="(cacheValue, index) in cacheValues" :key="cacheValue.key">
                            <v-row align="center" justify="center">
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="cacheValue.value"
                                        :hint="isChanged(index) ? '* Unsaved changes' : ''"
                                        persistent-hint
                                        :readonly="loading"
                                        :rules="[required, version]"
                                        :label="cacheValue.key"
                                    />
                                </v-col>
                                <v-col class="d-flex align-center" style="margin-bottom: 20px">
                                    <v-btn
                                        :disabled="!isChanged(index) || !isFormValid(index)"
                                        color="primary"
                                        @click="updateCacheEntry(cacheValue, index)"
                                        :loading="loading"
                                    >
                                        <template v-slot:loader>
                                            <v-progress-circular color="primary" indeterminate />
                                        </template>
                                        Update
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-window-item>
        </v-window>
    </v-card>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Cookies from 'js-cookie';
import { type CacheEntry, createCacheAdministrationService } from '@/store/cacheAdministrationService';
import { eventBus } from '@/store/eventBus';

const tab = ref('one');
const loading = ref(true);
const cacheValues = ref<CacheEntry[]>([]);
const fetchedValues = ref<string[]>([]);

const accessToken = Cookies.get('access_token')!;
const cacheAdminService = createCacheAdministrationService(accessToken);

async function fetchData() {
    try {
        loading.value = true;
        const values = await cacheAdminService.getAllCacheValues();
        fetchedValues.value = [...values.map((x) => x.value)];
        cacheValues.value = values;
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        loading.value = false;
    }
}

async function updateCacheEntry(cacheValue: CacheEntry, index: number): Promise<void> {
    try {
        loading.value = true;
        // Use a copy of the object to avoid mutating the reactive proxy directly
        const updatedCacheValue = { ...cacheValue };

        await cacheAdminService.saveCacheValue(updatedCacheValue);
        fetchedValues.value[index] = updatedCacheValue.value;

        eventBus.showSuccess(`Successfully updated cache entry ${updatedCacheValue.key}`);
    } catch (error) {
        eventBus.showError('Unexpected error occured while saving cache entry');
        console.error('Error updating cache entry:', error);
    } finally {
        loading.value = false;
    }
}

function required(value: string): boolean | string {
    const valid = !!value || 'Field is required';

    return valid;
}

function version(version: string) {
    const versionRegex = /^\d+\.\d+\.\d+$/;
    const validVersion = versionRegex.test(version);
    if (!validVersion) {
        return 'Invalid version';
    }
    return true;
}

function isFormValid(index: number) {
    const value = cacheValues.value[index].value;
    const isValidVersion = version(value) === true;
    const hasValue = required(value) === true;
    return isValidVersion && hasValue;
}

function isChanged(index: number) {
    const value = cacheValues.value[index].value;
    const originalValue = fetchedValues.value[index];

    console.log('value', value, originalValue);
    return value !== originalValue;
}

// Fetch data on component mount
fetchData();
</script>

<style scoped>
.container {
    padding-top: 20px;
}
</style>
