import { type AuthenticationData } from '@/types/AuthenticationData';
import { get, post } from './fetchService';
import { type ValidateData } from '@/types/ValidateData';

export const createAuthService = () => {
    return {
        validate: async (validateData: ValidateData): Promise<AuthenticationData> => {
            const authenticationData = await post<AuthenticationData, ValidateData>('/admin-tool/validate', validateData);

            return authenticationData;
        },
        refreshToken: async (refreshToken: string): Promise<AuthenticationData> => {
            const response = await get<AuthenticationData>('/admin-tool/tokens', refreshToken);

            return response;
        }
    };
};
